require("@rails/ujs").start();

import * as ActiveStorage from "@rails/activestorage"

require("@rails/actiontext");
import "@hotwired/turbo-rails"
import Trix from "trix"
import "controllers"
import "chartkick/chart.js"

// Stylesheets
//
import "trix/dist/trix.css"
import "stylesheets/application"

document.addEventListener('trix-attachment-add', function (event) {

  event.preventDefault();

  console.log('ActiveSupport:', event);

  var file = event.attachment.file;
  if (file) {
    var upload = new ActiveStorage.DirectUpload(file,'/rails/active_storage/direct_uploads', window);
    upload.create((error, attributes) => {
      if (error) {
        return false;
      } else {
        return event.attachment.setAttributes({
          url: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
          href: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
        });
      }
    });
  }
});

Trix.config.textAttributes.mark = {
  tagName: "mark",
  inheritable: true
}

document.addEventListener("trix-initialize", function(event) {
  var buttonHTML, buttonGroup
  buttonHTML = `<button class="trix-toolbar trix-button" type="button" data-trix-attribute="mark"><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><g fill="#626160" class="nc-icon-wrapper"><path fill="#626160" d="M22.7,7.3L21.4,6l-6.7,6.7C14.5,12.9,14.3,13,14,13s-0.5-0.1-0.7-0.3l-2-2c-0.4-0.4-0.4-1,0-1.4L18,2.6 l-1.3-1.3c-0.4-0.4-1-0.4-1.4,0l-12,12c-0.4,0.4-0.4,1,0,1.4l6,6C9.5,20.9,9.7,21,10,21s0.5-0.1,0.7-0.3l12-12 C23.1,8.3,23.1,7.7,22.7,7.3z"></path> <path data-color="color-2" d="M3.2,17.4l-1.9,1.9C1,19.5,0.9,19.9,1,20.2c0.1,0.3,0.3,0.6,0.7,0.7l3,1C4.8,22,4.9,22,5,22 c0.3,0,0.5-0.1,0.7-0.3l0.9-0.9L3.2,17.4z"></path></g></svg></button>`;

  buttonGroup = event.target.toolbarElement.querySelector(".trix-button-group.trix-button-group--block-tools")
  buttonGroup.insertAdjacentHTML("beforeend", buttonHTML)
})
