import "flatpickr/dist/flatpickr.css"
import 'lightgallery/css/lightgallery.css'

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

import Reveal from "stimulus-reveal-controller"
import NestedForm from "stimulus-rails-nested-form"
import TextareaAutogrow from "stimulus-textarea-autogrow"
import Flatpickr from 'stimulus-flatpickr'
import { Tabs } from "tailwindcss-stimulus-components"
import Lightbox from "stimulus-lightbox"

application.register("lightbox", Lightbox)
application.register('tabs', Tabs)
application.register('flatpickr', Flatpickr)
application.register("reveal", Reveal)
application.register("nested-form", NestedForm)
application.register("textarea-autogrow", TextareaAutogrow)
